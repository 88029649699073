import React, { useState } from 'react';
import { Grid, Button, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

const useStyles = makeStyles({
  root: {
    // minHeight: '100vh',
    padding: 10,
    // justifyContent: 'center',
    // alignItems: 'center',
    // display: 'flex'
  },
});
export default function UpdateCourse() {
  const classes = useStyles();
  const history = useHistory();
  let course = JSON.parse(sessionStorage.getItem('updateCourse'));
  const [title, setTitile] = useState(course.title);
  const [subtitle, setSubtitile] = useState(course.subtitle);
  const [headlines, setHeadlines] = useState(course.headlines);
  const [prerequisites, setPrerequisites] = useState(course.prerequisites);
  const [discount, setDiscount] = useState(course.discount);
  const [price, setPrice] = useState(course.price);
  const [description, setDescription] = useState(course.description);
  const [demolink, setDemolink] = useState(course.demo_link);
  const [slug, setSlug] = useState(course.slug);
  const [image, setImage] = useState('');

  const updateCourse = () => {
    let status;
    let formData = new FormData();
    formData.append('id', course.id);
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('headlines', headlines);
    formData.append('prerequisites', prerequisites);
    formData.append('discount', discount);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('demo_link', demolink);
    formData.append('slug', slug);
    if (image && image.name.length > 0) {
      formData.append('course_image', image);
    }
    fetch('https://api.reactdeveloper.ir/api/v1/admin/courses/update', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.token_type + ' ' + localStorage.token,
      },
      body: formData,
    })
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((responseJson) => {
        if (status === 200) {
          history.push('/admin-panel/courses');
        } else if (status === 401) {
          localStorage.clear();
          window.location.reload();
        }
      });
  };
  return (
    <Grid container className={classes.root}>
      <Grid container>
        <Grid item lg={4} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='عنوان'
            style={{ width: '100%' }}
            variant='outlined'
            value={title}
            onChange={(e) => setTitile(e.target.value)}
          />
        </Grid>
        <Grid item lg={4} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='slug'
            style={{ width: '100%' }}
            variant='outlined'
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
          />
        </Grid>
        <Grid item lg={2} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='قیمت'
            style={{ width: '100%' }}
            variant='outlined'
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Grid>
        <Grid item lg={2} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='میزان تخفیف'
            style={{ width: '100%' }}
            variant='outlined'
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
        </Grid>

        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='زیر عنوان'
            style={{ width: '100%' }}
            variant='outlined'
            value={subtitle}
            onChange={(e) => setSubtitile(e.target.value)}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='demo_link'
            style={{ width: '100%' }}
            variant='outlined'
            value={demolink}
            onChange={(e) => setDemolink(e.target.value)}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='پیش نیاز ها با :: از هم جدا شوند'
            style={{ width: '100%' }}
            variant='outlined'
            value={prerequisites}
            onChange={(e) => setPrerequisites(e.target.value)}
            multiline
            rows={2}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <TextField
            inputProps={{ className: 'whiteinput' }}
            label='مباحثی که در دوره گفته می شود با :: از هم جدا شوند'
            style={{ width: '100%' }}
            variant='outlined'
            value={headlines}
            onChange={(e) => setHeadlines(e.target.value)}
            multiline
            rows={4}
          />
        </Grid>
        <Grid
          item
          lg={12}
          style={{
            padding: 5,
            justifyContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Button component='label' variant='outlined' color='primary'>
            انتخاب تصویر دوره
            <input
              onChange={(e) => setImage(e.target.files[0])}
              type='file'
              style={{ display: 'none' }}
            />
          </Button>
          <span style={{ margin: 10 }}>{image ? image.name : ''}</span>
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <Editor
            initialValue={description}
            init={{
              plugins: [
                'advlist autolink lists link image directionality autoresize charmap print textcolor preview layer anchor',
                'searchreplace visualblocks codesample code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              // 'link image directionality image autoresize codesample code textcolor lists advlist layer media',
              menubar: 'insert tools',
              codesample_languages: [
                { text: 'HTML/XML', value: 'markup' },
                { text: 'JavaScript', value: 'javascript' },
                { text: 'CSS', value: 'css' },
                { text: 'Python', value: 'python' },
              ],
              toolbar:
                'paste | undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | ltr rtl | image | codesample | code | forecolor backcolor | bullist numlist outdent indent | removeformat | link | media',
              image_caption: true,
              image_advtab: true,
            }}
            onEditorChange={(content, editor) => setDescription(content)}
          />
        </Grid>
        <Grid item lg={12} style={{ padding: 5 }}>
          <Button
            onClick={updateCourse}
            fullWidth
            variant='outlined'
            color='primary'
          >
            ویرایش دوره
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
