import React from 'react'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Typography from '@material-ui/core/Typography'


function getSteps() {
  return ['JavaScript & EcmaScript',
    'React Js',
    'Material Ui',
    'پروژه وب سایت شخصی',
    'ارتباط با سرور',
    'ساخت یک وب سایت آموزشی به همراه پنل کاربری و مدیریت',
    'پروژه چت روم',
    'تست نویسی',
    'پروژه وب سایت رزرو بلیط',
    'خروجی،آپلود و مباحث پایانی',
  ]
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return `
در ابتدا مرور کوتاهی بر جاوا اسکریپت خواهیم داشت و مباحث مهم اکما اسکریپت را آموزش می دهیم.
      `
    case 1:
      return `
در گام بعدی آموزش با فریمورک React به صورت کامل آشنا می شوید. چگونگی ساخت پروژه، 
prop ها، state ها، کامپوننت، navigation، ساختار پروژه React و تمام مباحث این فریمورک به طور کامل آموزش داده می شود.
      `
    case 2:
      return `
در گام بعدی آموزش فریمورک های طراحی رابط کاربری در react  را معرفی می کنیم و شما را به طور کامل با Material ui آشنا می کنیم. 
پس از پایان این مرحله شما قادر هستید هر رابط کاربری را طراحی کنید.
      `
    case 3:
      return `
در این گام از آموزش با مرور مباحث گفته شده و تکنیک های طراحی یک وب سایت شخصی دو زبانه و حرفه ای طراحی می کنیم .
      `
    case 4:
      return `
در این بخش از آموزش ارتباط با سرور به طور کامل آموزش داده می شود. (هم ارتباط با RestFull api , هم ارتباط با GraphQL api, هم ارتباط با Socket io)
      `
    case 5:
      return `
در این گام از آموزش با استفاده از مباحث ارتباط با سرور و مباحث طراحی رابط کاربری که در جلسات قبل گفته شد یک وب سایت آموزشگاهی کامل طراحی می کنیم.
      `
    case 6:
      return `
در این گام از آموزش با استفاده از مباحث ارتباط سوکت با Socket io که در جلسات قبل گفته شد یک چت روم ساده را پیاده سازی می کنیم.
      `
    case 7:
      return `
      در این بخش از آموزش تست نویسی به وسیله دو فریمورک Jest و Enzyme آموزش داده می شود.
      `
    case 8:
      return `
در این بخش از آموزش یک وب سایت تجاری رزرو بلیط را با همدیگر طراحی می کنیم و به طراحی استاندارد و اصولی وب سایت های تجاری بزرگ مسلط می شوید.
      `
    case 9:
      return `
در بخش آخر آموزش چگونگی خروجی گرفتن استاندارد، آپلود بر روی سرور و  نکات باقی مانده گفته می شود. 
      `

    default:
      return 'Unknown step'
  }
}
class MainCourseStepper extends React.Component {
  render() {
    const steps = getSteps()
    return (
      <Stepper style={{ backgroundColor: 'transparent' }} orientation="vertical">
        {steps.map((label, index) => {
          return (
            <Step key={index} active={true} >
              <StepLabel>
                <Typography variant="h6" style={{ textAlign: 'right' }}>
                  {label}
                </Typography>
              </StepLabel>
              <StepContent  >
                <Typography variant="subtitle2" >{getStepContent(index)}</Typography>
              </StepContent>
            </Step>
          )
        })}
      </Stepper>
    )
  }
}

export default MainCourseStepper