import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#2f3243"
    }
})
export default function Gallerries() {
    const classes = useStyles()
    const [galleries, setGalleries] = useState([])
    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')

    useEffect(() => {
        let status
        fetch('https://api.reactdeveloper.ir/api/v1/admin/gallery', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setGalleries(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])

    const add = () => {
        let status
        let formData = new FormData()
        formData.append('title', title)
        formData.append('image', image)
        fetch('https://api.reactdeveloper.ir/api/v1/admin/gallery/add', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            },
            body: formData
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setGalleries(responseJson.data)
                    setTitle('')
                    setImage('')
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    return (
        <Grid container className={classes.root} >
            <Grid container>
                <Grid item lg={4} style={{ padding: 5 }} >
                    <TextField
                        inputProps={{ className: "whiteinput" }}
                        label="عنوان"
                        style={{ width: "100%" }}
                        variant="outlined"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Grid>
                <Grid item lg={4} style={{ padding: 5, justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }} >
                    <Button
                        component="label"
                        variant="outlined"
                        color="primary"
                    >
                        انتخاب تصویر دوره
                        <input
                            onChange={(e) => setImage(e.target.files[0])}
                            type="file"
                            style={{ display: "none" }}
                        />
                    </Button>
                    <span style={{ margin: 10 }} >{image ? image.name : ""}</span>
                </Grid>
                <Grid item lg={4} style={{ padding: 5, justifyContent: 'flex-start', display: 'flex', alignItems: 'center' }} >
                    <Button onClick={add} fullWidth variant="outlined" color="primary">
                        ثبت
                    </Button>
                </Grid>

            </Grid>
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>عکس</TableCell>
                            <TableCell>عنوان</TableCell>
                            <TableCell align="left">لینک</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {galleries.map(row => (
                            <TableRow key={row.id}>
                                <TableCell align="left">
                                    <img src={row.url} alt={row.title} style={{ width: 140, height: 70 }} />
                                </TableCell>
                                <TableCell align="left">{row.title}</TableCell>
                                <TableCell align="left">
                                    <Button component="a" href={row.url}>
                                        {row.url}
                                    </Button>
                                </TableCell>
                                <TableCell align="left">{row.tracking_code}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
