import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Player } from 'video-react'
import "video-react/dist/video-react.css" // import css
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 30,
        position: 'relatvie'
    },
    main: {
        cursor: 'pointer',
        position: 'relative',
        height: "100%",
        backgroundColor: "#2f3243",
        zIndex: 1,
        border: "1px solid #2e344e",
        borderTop: "5px solid #2e344e",
        // padding: theme.spacing(8),
        // alignItems: 'center',
        transition: ".4s",
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            transition: ".4s"
        }
    },

    title: {
        marginTop: 15,
        position: 'relative',
        paddingBottom: 15,
        "&::before": {
            content: "''",
            position: 'absolute',
            left: 0,
            top: 'auto',
            bottom: 0,
            height: 2,
            width: 50,
            background: ' #2e344e',
        }
    },
    desc: {
        marginTop: 5,
        textAlign: 'left'
    },
    topImage: {
        width: "100%"
    },
    tag: {
        backgroundColor: "#10c98d",
        position: 'absolute',
        right: 0,
        padding: 5,
        color: '#fff',
    },
    priceGrid: {
        background: ' #193d4a',
        borderRadius: 10,
    },
    text: {
        position: 'absolute',
        top: "50%",
        marginLeft: '18%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            margin: 20,
            top: "62%"
        }
    }
}))
export default function LessonVideo({ source }) {
    const classes = useStyles()
    return (
        <Grid item xs={12} md={6} lg={8} className={classes.root} >
            <Player
                playsInline
                src={source}
            />
            {source === "" ? (
                <Typography variant="h5" className={classes.text} >
                    برای تماشای ویدئو باید دوره را خریداری کنید.
                </Typography>
            ) : (null)}
        </Grid>
    )
}
