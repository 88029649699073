import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#2f3243"
    }
})
export default function Purchases() {
    const classes = useStyles()
    const [purchases, setPurchases] = useState([])
    useEffect(() => {
        let status
        fetch('https://api.reactdeveloper.ir/api/v1/admin/purchases', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setPurchases(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])

    return (
        <Grid container className={classes.root} >

            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>کاربر</TableCell>
                            <TableCell align="left">دوره</TableCell>
                            <TableCell align="left">قیمت</TableCell>
                            <TableCell align="left">وضعیت</TableCell>
                            <TableCell align="left">کدپیگیری</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {purchases.map(row => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.user ? row.user.email : ""}</TableCell>
                                <TableCell align="left">{row.course ? row.course.title : ""}</TableCell>
                                <TableCell align="left">{row.price}</TableCell>
                                <TableCell align="left">{row.status}</TableCell>
                                <TableCell align="left">{row.tracking_code}</TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
