import React, { useState, useEffect } from 'react'
import { Grid, Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'


const useStyles = makeStyles({
    root: {
        padding: 30,
    }
    ,
    whiteColor: {
        color: "#fff"
    },
    bgblack: {
        backgroundColor: "#2f3243"
    }
})
export default function Lessons() {
    const classes = useStyles()
    const [lessons, setLessons] = useState([])
    const [topics, setTopics] = useState([])
    const [topic, setTopic] = useState({})
    const [type, setType] = useState(0)
    const [newLessonTitle, setNewLessonTitle] = useState('')
    const [downloadLink, setDownloadLink] = useState('')
    const [description, setDescription] = useState('')
    const [time, setTime] = useState('')
    const [updateId, setUpdatedId] = useState(0)
    const [isFree, setIsFree] = useState(0)
    const [topicl, setTopicl] = useState({})
    const [lesson, setLesson] = useState({})
    //new 
    const [lessonType, setLessonType] = useState(0)
    useEffect(() => {
        let status
        fetch('https://api.reactdeveloper.ir/api/v1/admin/lessons', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setLessons(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
        fetch('https://api.reactdeveloper.ir/api/v1/admin/topics', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setTopics(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }, [])
    const onDelete = (id) => {
        let status
        fetch('https://api.reactdeveloper.ir/api/v1/admin/lessons/delete', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                id: id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setLessons(responseJson.data)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    const addProccess = () => {
        const url = type === 0 ? "https://api.reactdeveloper.ir/api/v1/admin/lessons/add" : "https://api.reactdeveloper.ir/api/v1/admin/lessons/update"
        let data = type === 0 ? {
            title: newLessonTitle,
            topic_id: topic.id,
            description: description,
            download_link: downloadLink,
            is_free: isFree,
            time: parseInt(time),
            type: lessonType
        } : {
                title: newLessonTitle,
                topic_id: topic.id,
                description: description,
                download_link: downloadLink,
                is_free: isFree,
                time: parseInt(time),
                type: lessonType,
                id: updateId
            }
        let status
        fetch(url, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': "application/json"
            },
            body: JSON.stringify(data)
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 201) {
                    setLessons(responseJson.data)
                    setNewLessonTitle('')
                    setIsFree(0)
                    setDescription('')
                    setDownloadLink('')
                    setType(0)
                    setTime('')
                    setTopics([])
                    setLessonType(0)
                } else if (status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }
            })
    }

    const addLessonToTopic = () => {
        let status
        fetch('https://api.reactdeveloper.ir/api/v1/admin/lessons/add-lesson-to-topic', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Authorization': localStorage.token_type + " " + localStorage.token,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                lesson_id: lesson.id,
                topic_id: topicl.id
            })
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                console.log(status, responseJson)

            })
    }

    return (
        <Grid container className={classes.root} >

            <Grid item lg={4} xs={12} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <Autocomplete
                    classes={{ popupIndicator: classes.whiteColor, paper: classes.bgblack }}
                    options={topics}
                    getOptionLabel={option => {
                        return option.title ? option.title : ""

                    }}
                    // style={{ width: 300 }}
                    renderInput={params => <TextField
                        {...params} label="انتخاب تاپیک" variant="outlined" />}
                    onChange={(e, t) => {
                        setTopicl(t)
                    }}
                    value={topicl}
                />
            </Grid>
            <Grid item lg={4} xs={12} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <Autocomplete
                    classes={{ popupIndicator: classes.whiteColor, paper: classes.bgblack }}
                    options={lessons}
                    getOptionLabel={option => {
                        return option.title ? option.title : ""

                    }}
                    // style={{ width: 300 }}
                    renderInput={params => <TextField
                        {...params} label="انتخاب درس" variant="outlined" />}
                    onChange={(e, t) => {
                        setLesson(t)
                    }}
                    value={lesson}
                />
            </Grid>
            <Grid item xs={12} lg={4} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <Button size="large" variant="outlined" color="primary" fullWidth onClick={addLessonToTopic} >
                    اضافه کردن درس به تاپیک
                </Button>
            </Grid>

            <Grid item xs={3} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <Autocomplete
                    classes={{ popupIndicator: classes.whiteColor, paper: classes.bgblack }}
                    options={topics}
                    getOptionLabel={option => {
                        return option.title ? option.title : ""

                    }}
                    // style={{ width: 300 }}
                    renderInput={params => <TextField
                        {...params} label="انتخاب تاپیک" variant="outlined" />}
                    onChange={(e, t) => {
                        setTopic(t)
                    }}
                    value={topic}
                />
            </Grid>
            <Grid item xs={3} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <TextField
                    value={newLessonTitle}
                    onChange={(e) => setNewLessonTitle(e.target.value)}
                    variant="outlined" fullWidth
                    label="عنوان درس"
                />
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <TextField
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    variant="outlined" fullWidth
                    label="زمان"
                />
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <Select
                    style={{ fontFamily: "IranSans", textAlign: 'right' }}
                    variant="outlined"
                    fullWidth
                    value={lessonType}
                    onChange={(e) => setLessonType(e.target.value)}
                    classes={{ icon: classes.whiteColor }}
                >
                    <MenuItem value={0}>آموزش</MenuItem>
                    <MenuItem value={1}>تمرین</MenuItem>
                    <MenuItem value={2}>پادکست صوتی</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={2} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <Select
                    style={{ fontFamily: "IranSans", textAlign: 'right' }}
                    variant="outlined"
                    fullWidth
                    value={isFree}
                    onChange={(e) => setIsFree(e.target.value)}
                    classes={{ icon: classes.whiteColor }}
                >
                    <MenuItem value={0}>رایگان</MenuItem>
                    <MenuItem value={1}>غیر رایگان</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <TextField
                    value={downloadLink}
                    onChange={(e) => setDownloadLink(e.target.value)}
                    variant="outlined" fullWidth
                    label="لینک دانلود"
                />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 25, paddingRight: 10, paddingLeft: 10 }} >
                <TextField
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    variant="outlined" fullWidth
                    label="توضیحات درس"
                    multiline
                    rows={3}
                />
            </Grid>

            <Grid item xs={12} style={{ marginBottom: 25 }} >
                <Button variant="outlined" color="primary" fullWidth onClick={addProccess} >
                    اضافه / ویرایش
                </Button>
            </Grid>
            <TableContainer component={Grid}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>عنوان درس</TableCell>
                            <TableCell align="left">تاپیک</TableCell>
                            <TableCell align="left">رایگان</TableCell>
                            <TableCell align="left">نوع</TableCell>
                            <TableCell align="left">زمان</TableCell>
                            <TableCell align="left">ویرایش</TableCell>
                            <TableCell align="left">حذف</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lessons.map(row => (
                            <TableRow key={row.id}>
                                <TableCell >
                                    {row.title}
                                </TableCell>
                                <TableCell align="left">{row.topics[0] ? row.topics[0].title : ""}</TableCell>
                                <TableCell align="left">{row.is_free === 1 ? "خیر" : "بله"}</TableCell>
                                <TableCell align="left">{row.type === 0 ? "آموزش" : row.type === 1 ? "تمرین" : "پادکست"}</TableCell>
                                <TableCell align="left">{row.time}</TableCell>
                                <TableCell align="left">
                                    <Button
                                        onClick={() => {
                                            setUpdatedId(row.id)
                                            setTopic(row.topics[0])
                                            setNewLessonTitle(row.title)
                                            setType(1)
                                            setTime(row.time)
                                            setIsFree(row.is_free)
                                            setDescription(row.description)
                                            setDownloadLink(row.download)
                                            setLessonType(row.type)
                                        }}
                                        variant="text" color="primary" >
                                        ویرایش
                                    </Button>
                                </TableCell>
                                <TableCell align="left">
                                    <Button onClick={() => onDelete(row.id)} variant="text" color="primary" >
                                        حذف
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Grid >
    )
}
