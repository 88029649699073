import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Title from '../../components/title';
import './contact.css';
import Contact from '../../components/contact';
import CallIcon from '@material-ui/icons/CallOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import TelegramIcon from '@material-ui/icons/Telegram';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import Teachers from '../../components/Teachers';
import { Helmet } from 'react-helmet';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  contactGrid: {
    paddingTop: 60,
    paddingBottom: 60,
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 5,
      paddingLeft: 5,
    },
  },
  FormGrid: {
    padding: 10,
  },
  detailsGrid: {
    padding: 10,
  },
  getintouch: {
    textAlign: 'left',
  },
  iconStyle: {
    fontSize: 30,
  },
  titr: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  pos: {
    marginTop: 15,
    width: '100%',
    textAlign: 'left',
  },
  poslist: {
    marginTop: 10,
    textAlign: 'left',
  },
}));
export default function ProjectScreen() {
  const classes = useStyles();
  return (
    <Grid alignItems='flex-start' container className={classes.root}>
      <Helmet>
        <title>درخواست پروژه</title>
        <meta
          name='description'
          content=' تیم ما با مجرب ترین برنامه نویسان آمادگی انجام انواع پروژه های شما را به با مدرن ترین تکنولوژی های روز
                    و به صورت کاملا حرفه ای و استاندارد دارا می باشد. انواع پروژه های بک اند، فرانت اند و طراحی اپلیکیش خود را به مابسپارید و
                    پروژه هایی با کد نویسی قابل توسعه، قابل فهم، حرفه ای و مطابق با استانداردهای روز را تحویل بگیرید.'
        />
      </Helmet>

      <Grid className={classes.contactGrid} item container xs={12}>
        <Title title='درخواست پروژه' />
        <Typography variant='body1'>
          تیم ما با مجرب ترین برنامه نویسان آمادگی انجام انواع پروژه های شما را
          به با مدرن ترین تکنولوژی های روز و به صورت کاملا حرفه ای و استاندارد
          دارا می باشد. انواع پروژه های بک اند، فرانت اند و طراحی اپلیکیش خود را
          به مابسپارید و پروژه هایی با کد نویسی قابل توسعه، قابل فهم، حرفه ای و
          مطابق با استانداردهای روز را تحویل بگیرید.
        </Typography>
        <Typography variant='body1'>
          برای اعلام شرایط خود و درخواست پروژه میتوانید از طریق راه های زیر با
          ما ارتباط برقرار نمایید.
        </Typography>
        <Grid container direction='row'>
          <Grid item xs={12} md={6} className={classes.FormGrid}>
            <Contact
              icon={<CallIcon className={classes.iconStyle} />}
              title='تلفن تماس'
              text1='021-66438930'
              text2='09226686036'
              text3='09220479451'
            />
            <Contact
              icon={<EmailIcon className={classes.iconStyle} />}
              title='ایمیل'
              text1='farshidk0594@gmail.com'
              text2='hamidk_1373@yahoo.com'
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.detailsGrid}>
            <Contact
              icon={<TelegramIcon className={classes.iconStyle} />}
              title='تلگرام / واتساپ'
              text1='asanyadbegir@'
              text2='09226686036'
              text3='09220479451'
            />
            <Contact
              icon={<RoomIcon className={classes.iconStyle} />}
              title='آدرس'
              text1='تهران، خیایان دکتر قریب، نبش خیابان طوسی، پلاک 26، ساختمان شباهنگ، واحد 29'
            />
          </Grid>
        </Grid>
        <Typography style={{ marginTop: 15 }} variant='subtitle2'>
          <span className={classes.titr}>توجه: </span>
          مراجعه حضوری با هماهنگی قبلی امکان پذیر است.
        </Typography>
      </Grid>
      <Teachers contactGrid={true} />
    </Grid>
  );
}
