import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import canada from '../assets/images/a6.jpg'
import iran1 from '../assets/images/a2.jpg'
import iran2 from '../assets/images/a5.jpg'
import iran3 from '../assets/images/a10.jpg'
import Title from './title'
const styles = theme => ({
    rootPaper: {
        padding: 10,
        marginTop: 15,
        background: 'transparent',
    },
    title: {
        margin: '23px 0 15px 0',
    },
    textBody: {
        marginBottom: 10,
        textAlign: 'justify',
        fontSize: 13,
        fontWeight: 500,
        lineHeight: 2,
        padding: 10
    },
    logos: {
        width: '100%',
    },
    imgsalary: {
        width: "80%"
    },
    imgsalaryIran: {
        width: "95%"
    }
})
const Salary = (props) => {
    const { classes } = props
    return (
        <Paper square className={classes.rootPaper}>
            <Grid container >
                <Title title="میزان حقوق برنامه نویسان React js" />
                <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.textBody}>
                        در حال حاضر خیلی از شرکت های ایرانی و خارجی در طراحی فرانت اند خود به سراغ فریمورک React Jsسوق پیدا کرده اند.
                        و روزانه ده ها آگهی استخدام در اینترنت قرار داده می شوند که شرکت های مختلف به متخصصین این حوزه نیاز دارند.
                        میانگین حقوق برنامه نویسان React در ایران بالای 5 میلیون می باشد و در خارج از کشور نیز افراد React  کاردرامد سالانه بالای صد هزار دلار
                        دارند. برای اطلاع از میزان درامد برنامه نویسان React چند آگهی استخدامی ایرانی و یک بخشی از سایت آگهی کاریابی کشور کانادا قرار داده شده است.
                    </Typography>
                    <img src={canada} className={classes.imgsalary} alt="آموزش React js" />
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={iran1} className={classes.imgsalaryIran} alt="آموزش React js" />
                    <img src={iran2} className={classes.imgsalaryIran} alt="آموزش React js" />
                    <img src={iran3} className={classes.imgsalaryIran} alt="آموزش React js" />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default withStyles(styles)(Salary)