import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Title from '../../components/title';
import './contact.css';
import Contact from '../../components/contact';
import CallIcon from '@material-ui/icons/CallOutlined';
import EmailIcon from '@material-ui/icons/EmailOutlined';
import TelegramIcon from '@material-ui/icons/Telegram';
import RoomIcon from '@material-ui/icons/RoomOutlined';
import { Helmet } from 'react-helmet';
import Teachers from '../../components/Teachers';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
  },
  contactGrid: {
    paddingTop: 60,
    paddingBottom: 0,
    paddingRight: 30,
    paddingLeft: 30,
    [theme.breakpoints.down('xs')]: {
      paddingRight: 5,
      paddingLeft: 5,
    },
  },
  FormGrid: {
    padding: 10,
  },
  detailsGrid: {
    padding: 10,
  },
  getintouch: {
    textAlign: 'left',
  },
  iconStyle: {
    fontSize: 30,
  },
  titr: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  pos: {
    marginTop: 15,
    width: '100%',
    textAlign: 'left',
  },
  poslist: {
    marginTop: 10,
    textAlign: 'left',
  },
}));
export default function ContactScreen() {
  const classes = useStyles();
  return (
    <Grid alignItems='flex-start' container className={classes.root}>
      <Helmet>
        <title>تماس با ما</title>
        <meta
          name='description'
          content='وب سایت ReactDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی طراحی فرانت اند وب سایت ها و وب اپلیکیشن با استفاده از React js و زبان برنامه نویسی جاوااسکرپیت است. و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود.'
        />
      </Helmet>

      <Grid className={classes.contactGrid} item container xs={12}>
        <Title title='تماس با ما' />
        <Grid container direction='row'>
          <Grid item xs={12} md={6} className={classes.FormGrid}>
            <Contact
              icon={<CallIcon className={classes.iconStyle} />}
              title='تلفن تماس'
              text1='021-66438930'
              text2='09226686036'
              text3='09220479451'
            />
            <Contact
              icon={<EmailIcon className={classes.iconStyle} />}
              title='ایمیل'
              text1='farshidk0594@gmail.com'
              text2='hamidk_1373@yahoo.com'
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.detailsGrid}>
            <Contact
              icon={<TelegramIcon className={classes.iconStyle} />}
              title='تلگرام / واتساپ'
              text1='asanyadbegir@'
              text2='09226686036'
              text3='09220479451'
            />
            <Contact
              icon={<RoomIcon className={classes.iconStyle} />}
              title='آدرس'
              text1='تهران، خیایان دکتر قریب، نبش خیابان طوسی، پلاک 26، ساختمان شباهنگ، واحد 29'
            />
          </Grid>
        </Grid>
        <Typography style={{ marginTop: 15 }} variant='subtitle2'>
          <span className={classes.titr}>توجه: </span>
          مراجعه حضوری با هماهنگی قبلی امکان پذیر است.
        </Typography>
      </Grid>
      <Teachers contactGrid={true} />
    </Grid>
  );
}
