import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import Title from '../../components/title'
import Service from '../../components/service'
import Course from '../../components/Course'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import PersonIcon from '@material-ui/icons/Person'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import { useHistory } from 'react-router-dom'
import Teachers from '../../components/Teachers'
import { getImageAddress, getTime } from '../../config/Utils'
import Moment from 'moment-jalaali'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Helmet } from "react-helmet"
import { scrollToTop } from '../../config/Utils'
import reactnativeimage from '../../assets/images/reactnative.png'
import { Link } from 'react-router-dom'
import MainCourseStepper from '../../components/MainCourseStepper'
import Salary from '../../components/Salary'

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: "100vh",
        position: 'relative',
        width: "100%",
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        alignItems: 'center',
        padding: 30,
        paddingTop: 50,
        [theme.breakpoints.down('xs')]: {
            padding: 10
        }
    },
    iconStyle: {
        fontSize: 46,
        color: theme.palette.primary.main
    },
    rootAll: {
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 30,
    },
    mainAll: {
        cursor: 'pointer',
        position: 'relative',
        height: "100%",
        backgroundColor: "#2f3243",
        zIndex: 1,
        border: "1px solid #2e344e",
        borderTop: "5px solid #2e344e",
        // padding: theme.spacing(8),
        // alignItems: 'center',
        transition: ".4s",
        '&:hover': {
            borderTopColor: theme.palette.primary.main,
            transition: ".4s"
        }
    },
    mainIntro: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    mainCourseIntro: {
        padding: 10
    }

}))
export default function HomeScreen() {
    const classes = useStyles()
    let history = useHistory()
    const [courses, setCourses] = useState([])
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        let status
        fetch('https://api.reactdeveloper.ir/api/v1/main-courses', {
            method: "GET",
            headers: {
                'Accept': 'application/json',
            }
        })
            .then((response) => {
                status = response.status
                return response.json()
            }).then((responseJson) => {
                if (status === 200) {
                    setCourses(responseJson.data)
                }
                setLoading(false)
            })
    }, [])
    return (
        <div className={classes.root} >
            <Helmet>
                <title>React js / طراحی فرانت اند وب سایت ها  و وب اپلیکیشن ها </title>
                <meta name="description" content="وب سایت ReactDeveloper یکی از زیر مجموعه های موسسه آموزشی آسان یادبگیر می باشد که تمرکز آن بر روی طراحی فرانت اند وب سایت ها و وب اپلیکیشن با استفاده از React js و زبان برنامه نویسی جاوااسکرپیت است. و آموزش های جامع و همچنین آموزش های موضوعی را در این زمینه به صورت کاملا حرفه ای و مفهومی در اختیار کاربران عزیز قرار می دهد. دوره های این مجموعه کاملا مطابق با متد های روز و به وسیله مدرسین مجرب و حرفه ای که چندین سال در این زمینه تجربه داشته اند تولید می شود." />
            </Helmet>
            <Grid container>
                {/* <Title title="درباره وب سایت" /> */}
                <Grid item xs={12} lg={3}>
                    <img style={{ width: "90%" }} src={reactnativeimage} alt="آموزش جامع react native" />
                </Grid>
                <Grid item xs={12} lg={9} className={classes.mainIntro} >
                    <Typography variant="h2" >
                        آموزش React js
                    </Typography>
                    <Typography variant="subtitle1" >
                        محبوب ترین فریمورک طراحی FrontEnd و Web app
                    </Typography>
                    <div style={{ marginTop: 20 }} >
                        <Button
                            component={Link}
                            to="/courses/react"
                            style={{ margin: 10, padding: 10 }} color="primary" variant="outlined" >   دوره جامع آموزش React js</Button>
                        <Button
                            component={Link}
                            to="/courses"
                            style={{ margin: 5, padding: 10 }} color="primary"  >مشاهده همه دوره ها</Button>
                    </div>
                </Grid>
            </Grid>
            <Grid direction="row" container style={{ marginTop: 30 }} >
                <Service
                    onClickProp={() => { scrollToTop(); history.push('/courses') }}
                    icon={<VideoLibraryIcon className={classes.iconStyle} />}
                    title="دوره های آنلاین ویدئویی"
                    desc="در این وب سایت دوره های آموزشی آنلاین ویدئویی طراحی فرانت اند وب سایت ها و وب اپلیکیشن با React js توسط مجرب ترین اساتید قرا می گیرد."
                />
                <Service
                    onClickProp={() => { scrollToTop(); history.push('/tutor') }}
                    icon={<PersonIcon className={classes.iconStyle} />}
                    title="درخواست کلاس خصوصی"
                    desc="در صورتی که درخواست کلاس خصوصی (حضوری یا از راه دور/آنلاین) در خصوص طراحی فرانت اند وب سایت ها و وب اپلیکیشن ها با React js دارید کلیک کنید."
                />
                <Service
                    onClickProp={() => { scrollToTop(); history.push('/request/project') }}
                    icon={<AccountTreeIcon className={classes.iconStyle} />}
                    title="درخواست پروژه"
                    desc="پروژه های خود را به مهندسین توانای آکادمی بسپارید و مدرین ترین، حرفه ای ترین و بهترین وب سایت ها را تحویل بگیرید."
                />
            </Grid>

            <Grid direction="row" container style={{ marginTop: 30 }} >
                <Title title="دوره های آموزشی" />
                <Typography variant="subtitle2" color="primary">
                    افرادی که دوره ها رو خریداری می کنند می توانند درخواست اضافه شدن قسمت جدید به دوره و یا دوباره ضبط شدن قسمتی
                    از دوره را داشته باشند. در صورتی که احساس می کنید باید مبحثی در دوره آموزش داده شود که جزو سر فصل های آن دوره نمی باشد
                    از طریق نظرات دوره با ما در میان بگذارید. مبحث درخواستی به سرفصل های دوره اضافه شده و در اسرع وقت تولید می شود.
                </Typography>
                {loading ? (
                    <CircularProgress />
                ) : (
                        <>
                            {courses.map((course) =>
                                <Course
                                    key={course.id}
                                    course_image={getImageAddress(course.course_image)}
                                    title={course.title}
                                    time={getTime(course.time)}
                                    lessonsCount={course.lessons_count}
                                    price={course.price}
                                    discountPrice={course.price * ((100 - course.discount) / 100)}
                                    slug={course.slug}
                                    isComplete={course.is_complete}
                                    updatedAt={Moment(course.updated_at).format('jD jMMMM jYYYY')}
                                />
                            )}
                            <Grid
                                onClick={() => { scrollToTop(); history.push('/courses/') }}
                                item lg={3} md={6} xs={12} className={classes.rootAll} >
                                <Grid className={classes.mainAll} container direction='column' alignItems="center" justify="center" >
                                    <Typography variant="h5" >
                                        مشاهده همه دوره ها
                            </Typography>
                                </Grid>
                            </Grid>
                        </>
                    )}

            </Grid>
            <Grid direction="row" container style={{ padding: 10, marginTop: 30, backgroundColor: "#2f3243" }}>
                <Grid item xs={12} className={classes.mainCourseIntro} >
                    <Typography variant="h5" style={{ textAlign: 'right', marginBottom: 10 }}  >دوره آموزش جامع React js</Typography>
                    {/* <h4>دوره آموزش جامع ReactJS</h4> */}
                    <Typography variant="body1" >
                        در این دوره فریمورک محبوب و معروف شرکت بزرگ فیس بوک به اسم React
                        آموزش داده می شود. این فریمورک در حال حاضر یکی از محبوب ترین فریمورک
                        ها برای طراحی FrontEnd یا همان طراحی ظاهر وب سایت هاست. با این
                        فریمورک می توان وب اپلیکیشن های با سرعت و پرفومنث عالی ساخت. پس از
                        یادگیری این فریمورک از قدرت بالای آن، سرعت بالای آن و پرفورمنث عالی
                        آن شگفت زده خواهیدشد. با استفاده از این فریمورک به سادگی می توانید
                        قالب های حرفه ای و پیشرفته ای برای وب سایت هایتان بسازید.
                    </Typography>
                    <Typography variant="body1" >
                        این دوره آموزش به همراه پروژه عملی می باشد. تفاوتی که با دوره های
                        پروژه محور دارد در این است که در دوره های پروژه محور فرض بر این می
                        باشد که کاربر با مباحث آشنایی دارد و فقط توانایی پیاده سازی پروژه را
                        ندارد ولی در این دوره مباحث نیز به طور کامل آموزش داده می شود. در
                        ابتدا مفاهیم و مباحث آموزش داده می شود و همزمان با آموزش هر مبحث، از
                        مبحث گفته شده در پروژه های مد نظر خودمان نیز استفاده می کنیم تا
                        همزمان با یادگیری با کاربرد عملی آن نیز آشنا شوید.
                    </Typography>
                    <Typography variant="body1" >
                        با دنبال کردن این دوره و تلاش، ممارست و تمرین می توانید پس از پایان
                        دوره به طور کامل به این فریمورک تسلط پیدا کنید و بعد از آن قادر
                        خواهید بود هر وب اپلیکیشن و وب سایتی را با این فریمورک به طور کاملا
                        حرفه ای استاندارد طراحی کنید. همچنین با توجه به انجام چند پروژه در
                        حین آموزش، شما بعد از پایان دوره با انجام دادن پروژه های گفته شده
                        (مانند پروژه وب سایت شخصی ) چند نمونه کار حرفه ای نیز خواهید داشت که
                        می توانید به رزومه تان اضافه کنید و پس از پایان دوره به راحتی
                        بتوانید در هر شرکتی با حقوق بالا استخدام شوید. مباحثی که در دوره
                        جامع گفته می شود به طور کامل در ادامه آمده است. در صورتی که به تمام
                        دوره جامع احتیاج ندارید می توانید به قسمت مشاهده همه دوره ها بروید.
                        بخش های مختلف دوره به صورت دوره های جداگانه در سایت قرار می گیرد.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <MainCourseStepper />
                    <Button
                        fullWidth
                        variant="outlined"
                        size="large"
                        color="primary"
                        component={Link} to="/courses/react"
                        style={{ marginTop: 10 }}
                    >
                        اطلاعات بیشتر و ثبت نام در دوره جامع آموزش ReactJS
                    </Button>
                    <Button
                        fullWidth
                        variant="text"
                        color="primary"
                        size="large"
                        component={Link} to="/courses"
                        style={{ marginTop: 10 }}
                    >
                        مشاهده همه دوره ها
                    </Button>

                </Grid>

            </Grid>

            <Grid direction="row" container >
                <Salary />
            </Grid>

            <Teachers />
        </div>
    )
}
